.drawer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  pointer-events: none;
}
.drawer > * {
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), box-shaow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), box-shaow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), box-shaow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-bg {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.drawer-content-wrapper {
  position: absolute;
  height: 100%;
}
.drawer-content {
  background: #fff;
  height: 100%;
  overflow: auto;
  z-index: 1;
  position: relative;
}
.drawer-button {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  pointer-events: auto;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
}
.drawer-button-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-button-icon:before,
.drawer-button-icon:after {
  content: '';
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-button-icon:before {
  top: -5px;
}
.drawer-button-icon:after {
  top: 5px;
}
.drawer-left .drawer-button {
  right: -40px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-wrapper {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-right .drawer-button {
  left: -40px;
  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-wrapper {
  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer.drawer-open {
  pointer-events: auto;
}
.drawer.drawer-open .drawer-bg {
  opacity: .3;
}
.drawer.drawer-open .drawer-button-icon {
  background: transparent;
}
.drawer.drawer-open .drawer-button-icon:before {
  -webkit-transform: translateY(5px) rotate(45deg);
          transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-button-icon:after {
  -webkit-transform: translateY(-5px) rotate(-45deg);
          transform: translateY(-5px) rotate(-45deg);
}
